import { Action, createReducer, on } from '@ngrx/store';
import * as featureFlagActions from './feature-flag.actions';
import { DgPayoutVendors } from '../../apis/misc/types';

export interface FeatureFlagState {
  dgUseInitiatePayout: boolean;
  dgPayoutVendors: DgPayoutVendors;
}

const initialState: FeatureFlagState = {
  dgUseInitiatePayout: false,
  dgPayoutVendors: {} as DgPayoutVendors,
};

const reducer = createReducer(
  initialState,
  on(
    featureFlagActions.setDgUseInitiatePayout,
    (state, { dgUseInitiatePayout }) => ({
      ...state,
      dgUseInitiatePayout,
    }),
  ),
  on(featureFlagActions.setDgPayoutVendors, (state, { dgPayoutVendors }) => ({
    ...state,
    dgPayoutVendors,
  })),
);

export function featureFlagReducer(
  state: FeatureFlagState | undefined,
  action: Action,
): FeatureFlagState {
  return reducer(state, action);
}
