import { Action, createReducer, on } from '@ngrx/store';
import * as spreedlyActions from './spreedly.actions';

export interface SpreedlyState {
  areValidData: boolean;
  paymentToken?: string;
}

const initialState: SpreedlyState = {
  areValidData: false,
};

const reducer = createReducer(
  initialState,
  on(spreedlyActions.setAreValidData, (state, { areValidData }) => ({
    ...state,
    areValidData,
  })),
  on(spreedlyActions.setPaymentToken, (state, { paymentToken }) => ({
    ...state,
    paymentToken,
  })),
);

export function spreedlyReducer(
  state: SpreedlyState | undefined,
  action: Action,
): SpreedlyState {
  return reducer(state, action);
}
