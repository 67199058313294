import { createAction, props } from '@ngrx/store';

export const setAreValidData = createAction(
  '[Global] Set the areValidData flag',
  props<{ areValidData: boolean }>(),
);

export const setPaymentToken = createAction(
  '[Spreedly] Set the paymentToken',
  props<{ paymentToken: string }>(),
);
