import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { reducers } from './app/shared/ngrx';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';

const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storeFreeze]
  : [];

if (environment.production) {
  enableProdMode();
}

async function initializeWorker() {
  if (environment.mock) {
    const { worker } = await import('./app/shared/apis/mocks/browser');
    return worker.start({
      onUnhandledRequest: 'warn',
    });
  }
  return Promise.resolve();
}

initializeWorker().then(() => {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        // Angular Modules
        BrowserModule,

        // Custom Modules
        AppRoutingModule,

        // Misc Modules
        TranslateModule.forRoot(),

        // Ngrx Modules
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          connectInZone: true,
        }),
        StoreRouterConnectingModule.forRoot(),
      ),
      {
        provide: DateAdapter,
        useClass: DateFnsAdapter,
        deps: [MAT_DATE_LOCALE],
      },
      { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
      DatePipe,
      provideAnimations(),
    ],
  }).catch((err) => console.error(err));
});
