export enum ExerciseActionStep {
  SEND_VERIFICATION_STEP = 'Send verification code',
  CHECK_VERIFICATION_STEP = 'Check verification code',
  CHOICE_STEP = 'Choice',

  HW_FORM_STEP = 'Hyperwallet Form Step',
  HW_REFUND_STEP = 'Hyperwallet Refund Step',
  HW_CONFIRMATION_STEP = 'Hyperwallet Confirmation Step',

  SL_FORM_STEP = 'Spreedly Form Step',
  SL_CONFIRMATION_STEP = 'Spreedly Confirmation Step',

  REBOOKING_SEARCH_STEP = 'Rebooking Search Step',
  REBOOKING_REVIEW_STEP = 'Rebooking Review Step',
  REBOOKING_FORM_STEP = 'Rebooking Form Step',
  REBOOKING_CONFIRMATION_STEP = 'Rebooking Confirmation Step',
}
