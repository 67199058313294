import { Action, createReducer, on } from '@ngrx/store';
import * as homeActions from './home.actions';
import {
  DgAlternative,
  DgCustomerItinerarySlice,
  DgExerciseCustomerResponse,
} from '@hts/partner-airlines-api-client';
import { ExerciseActionStep } from '../../enums/exercise-action-step.enum';
import { environment } from 'src/environments/environment';
import { Partner } from '../../enums/partner.enum';

export interface HomeState {
  isLoading: boolean;
  isLoadingRebook: boolean;
  step: ExerciseActionStep;
  errorCode?: string;
  customErrorMessage?: string;
  userEmail: string;
  contractId?: string;
  segmentId?: string;
  sessionId?: string;
  isVerificationCodeAlreadySent: boolean;
  verificationCode?: string;
  hasDisruption: boolean;
  coveragePercentage?: number;
  exercise?: DgExerciseCustomerResponse;
  isLoadingHyperwallet: boolean;
  selectedAlternative?: DgAlternative;
  selectedSlice?: DgCustomerItinerarySlice;
  contactFormUrl: string;
  isFlowCompleted: boolean;
  currencyCode?: string;
  partnerId?: Partner;
  partnerName?: string;
  marketingCarrierCode?: string;
  serviceCap?: string;
  minMinutesDelay?: number;
  maxHoursBeforeDeparture?: number;
  paymentProviderKey?: string;
}

const initialState: HomeState = {
  isLoading: false,
  isLoadingRebook: false,
  step: ExerciseActionStep.SEND_VERIFICATION_STEP,
  userEmail: 'sample@hopper.com',
  isVerificationCodeAlreadySent: false,
  hasDisruption: false,
  isLoadingHyperwallet: false,
  isFlowCompleted: false,
  contactFormUrl: environment.defaultContactFormUrl,
};

const reducer = createReducer(
  initialState,
  on(homeActions.setIsLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(homeActions.setIsLoadingRebook, (state, { isLoadingRebook }) => ({
    ...state,
    isLoadingRebook,
  })),
  on(homeActions.setStep, (state, { step }) => ({ ...state, step: step })),
  on(homeActions.setErrorCode, (state, { errorCode }) => ({
    ...state,
    errorCode,
  })),
  on(homeActions.clearErrorCode, (state) => ({
    ...state,
    errorCode: undefined,
  })),
  on(homeActions.setCustomErrorMessage, (state, { customErrorMessage }) => ({
    ...state,
    customErrorMessage,
  })),
  on(homeActions.clearCustomErrorMessage, (state) => ({
    ...state,
    customErrorMessage: undefined,
  })),
  on(homeActions.setUserEmail, (state, { userEmail }) => ({
    ...state,
    userEmail,
  })),
  on(homeActions.setContractId, (state, { contractId }) => ({
    ...state,
    contractId,
  })),
  on(homeActions.setSegmentId, (state, { segmentId }) => ({
    ...state,
    segmentId,
  })),
  on(homeActions.setSessionId, (state, { sessionId }) => ({
    ...state,
    sessionId,
  })),
  on(
    homeActions.setIsVerificationCodeAlreadySent,
    (state, { isVerificationCodeAlreadySent }) => ({
      ...state,
      isVerificationCodeAlreadySent,
    }),
  ),
  on(homeActions.setVerificationCode, (state, { verificationCode }) => ({
    ...state,
    verificationCode,
  })),
  on(homeActions.setCoveragePercentage, (state, { coveragePercentage }) => ({
    ...state,
    coveragePercentage,
  })),
  on(homeActions.setExercise, (state, { exercise }) => ({
    ...state,
    exercise,
  })),
  on(homeActions.setSelectedAlternative, (state, { selectedAlternative }) => ({
    ...state,
    selectedAlternative,
  })),
  on(homeActions.setSelectedSlice, (state, { selectedSlice }) => ({
    ...state,
    selectedSlice,
  })),
  on(homeActions.setHasDisruption, (state, { hasDisruption }) => ({
    ...state,
    hasDisruption,
  })),
  on(homeActions.setContactFormUrl, (state, { contactFormUrl }) => ({
    ...state,
    contactFormUrl,
  })),
  on(homeActions.setIsFlowCompleted, (state, { isFlowCompleted }) => ({
    ...state,
    isFlowCompleted,
  })),
  on(homeActions.setCurrencyCode, (state, { currencyCode }) => ({
    ...state,
    currencyCode,
  })),
  on(homeActions.setPartnerId, (state, { partnerId }) => ({
    ...state,
    partnerId,
  })),
  on(homeActions.setPartnerName, (state, { partnerName }) => ({
    ...state,
    partnerName,
  })),
  on(
    homeActions.setMarketingCarrierCode,
    (state, { marketingCarrierCode }) => ({
      ...state,
      marketingCarrierCode,
    }),
  ),
  on(homeActions.setServiceCap, (state, { serviceCap }) => ({
    ...state,
    serviceCap,
  })),
  on(homeActions.setMinMinutesDelay, (state, { minMinutesDelay }) => ({
    ...state,
    minMinutesDelay,
  })),
  on(
    homeActions.setMaxHoursBeforeDeparture,
    (state, { maxHoursBeforeDeparture }) => ({
      ...state,
      maxHoursBeforeDeparture,
    }),
  ),
  on(homeActions.setPaymentProviderKey, (state, { paymentProviderKey }) => ({
    ...state,
    paymentProviderKey,
  })),
);

export function homeReducer(
  state: HomeState | undefined,
  action: Action,
): HomeState {
  return reducer(state, action);
}
