import { createAction, props } from '@ngrx/store';
import { DgPayoutVendors } from '../../apis/misc/types';

export const setDgUseInitiatePayout = createAction(
  '[FeatureFlag] Set the dgUseInitiatePayout boolean',
  props<{ dgUseInitiatePayout: boolean }>(),
);

export const setDgPayoutVendors = createAction(
  '[FeatureFlag] Set the dgPayoutVendors object',
  props<{ dgPayoutVendors: DgPayoutVendors }>(),
);
