import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../ngrx';
import { DateAdapter } from '@angular/material/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  getCurrentLang,
  getCurrentTheme,
  getIsLoadingConfiguration,
} from '../../ngrx/global/global.selectors';
import { Locales } from '../../i18n/constants';
import { CustomLocale } from '../../enums/language-code.enum';
import { setCurrentLang } from '../../ngrx/global/global.actions';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NavBarComponent } from '../navbar/navbar.component';
import {
  dateFnsLocales,
  getLanguageFromBrowser,
} from '../../utils/locale/locale.utils';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Theme } from '../../enums/theme.enum';
import { BidiModule } from '@angular/cdk/bidi';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    NavBarComponent,
    RouterOutlet,
    MatProgressSpinnerModule,
    CommonModule,
    BidiModule,
    TranslatePipe,
  ],
})
export class LayoutComponent {
  public isLoadingConfiguration!: boolean;

  private _currentTheme!: Theme;

  constructor(
    private _store: Store<AppState>,
    private _adapter: DateAdapter<any>,
    private _translateService: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    public layoutService: LayoutService,
  ) {
    // Set Labels
    Locales.forEach((data: Object, lang: string) => {
      this._translateService.setTranslation(lang, data, true);
    });

    // Set availables languages
    const availableLocales = Array.from(Locales.keys());
    this._translateService.addLangs(availableLocales);

    // Fallback language
    this._translateService.setDefaultLang(CustomLocale.EN);

    // Set default language
    const defaultLang = getLanguageFromBrowser(
      this._translateService.getBrowserCultureLang() || CustomLocale.EN,
    );

    // Set the current lang in the Store
    this._store.dispatch(
      setCurrentLang({ currentLang: defaultLang as CustomLocale }),
    );

    // Observables from NgRx store
    this._initNgRxListeners(inject(DestroyRef));
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngAfterContentChecked(): void {
    this._changeDetector.detectChanges();
  }

  // -----------------------------------------------
  // Handlers
  // -----------------------------------------------

  // ...

  // -----------------------------------------------
  // Public
  // -----------------------------------------------

  // ...

  // -----------------------------------------------
  // Private
  // -----------------------------------------------

  private _initNgRxListeners(ref: DestroyRef): void {
    // update the currentLang when it's changing
    this._store
      .pipe(select(getCurrentLang), takeUntilDestroyed(ref))
      .subscribe((currentLanguage: CustomLocale) => {
        // Set language in differents sources
        this._loadAngularCommonLocale(currentLanguage);
        this._loadDateFnsLocale(currentLanguage);
        this._translateService.use(currentLanguage);
      });

    this._store
      .pipe(select(getIsLoadingConfiguration), takeUntilDestroyed(ref))
      .subscribe(
        (isLoadingConfiguration: boolean) =>
          (this.isLoadingConfiguration = isLoadingConfiguration),
      );

    this._store
      .pipe(select(getCurrentTheme), takeUntilDestroyed(ref))
      .subscribe((currentTheme: Theme) => {
        // Remove old theme
        document.body.classList.remove(this._currentTheme);

        // Add the new theme
        this._currentTheme = currentTheme;
        document.body.classList.add(currentTheme);
      });
  }

  private _loadDateFnsLocale = (locale: CustomLocale) => {
    const targetModule =
      locale in dateFnsLocales
        ? dateFnsLocales[locale]
        : dateFnsLocales[CustomLocale.EN];

    targetModule().then((module) => {
      this._adapter.setLocale(module.default);
    });
  };

  private _loadAngularCommonLocale = (lang: CustomLocale) => {
    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-extra" */
      `@/../node_modules/@angular/common/locales/${lang}.mjs`
    ).then((module) => registerLocaleData(module.default));
  };
}
