import { ActionReducerMap } from '@ngrx/store';
import { globalReducer, GlobalState } from './global/global.reducer';
import { routerReducer } from '@ngrx/router-store';
import { RouterState } from '@angular/router';
import { homeReducer, HomeState } from './home/home.reducer';
import { spreedlyReducer, SpreedlyState } from './spreedly/spreedly.reducer';
import {
  featureFlagReducer,
  FeatureFlagState,
} from './feature-flag/feature-flag.reducer';

export interface AppState {
  global: GlobalState;
  home: HomeState;
  router: RouterState;
  spreedly: SpreedlyState;
  featureFlag: FeatureFlagState;
}

export const reducers: ActionReducerMap<AppState> = {
  global: globalReducer,
  home: homeReducer,
  router: routerReducer,
  spreedly: spreedlyReducer,
  featureFlag: featureFlagReducer,
};
