import { Action, createReducer, on } from '@ngrx/store';
import * as globalActions from './global.actions';
import { CustomLocale } from '../../enums/language-code.enum';
import { environment } from '../../../../environments/environment';
import { Theme } from '../../enums/theme.enum';

export interface GlobalState {
  currentLang: CustomLocale;
  currentTheme: Theme;
  isLoadingConfiguration: boolean;
}

const initialState: GlobalState = {
  currentLang: CustomLocale.EN,
  currentTheme: environment.defaultTheme,
  isLoadingConfiguration: true,
};

const reducer = createReducer(
  initialState,
  on(globalActions.setCurrentLang, (state, { currentLang }) => ({
    ...state,
    currentLang,
  })),
  on(globalActions.setCurrentTheme, (state, { currentTheme }) => ({
    ...state,
    currentTheme: currentTheme,
  })),
  on(
    globalActions.setIsLoadingConfiguration,
    (state, { isLoadingConfiguration }) => ({
      ...state,
      isLoadingConfiguration,
    }),
  ),
);

export function globalReducer(
  state: GlobalState | undefined,
  action: Action,
): GlobalState {
  return reducer(state, action);
}
